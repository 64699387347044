export default async function () {
  const userStore = useUserStore()
  const commonStore = useCommonStore()

  const { query } = useRoute()

  userStore.userInfo.distCode = query.campaign_id as string || 'azgo'
  userStore.userInfo.memberId = (query.memberId || query.member_id) as string || ''

  if (query?.memberId && !userStore.userInfo.accessToken) {
    await userStore.fetchLoginToken()
  }

  await commonStore.getIndexConfig()

  // 设置默认币种
  if (!userStore.userInfo.currency) {
    userStore.userInfo.currency = query.currency as string || commonStore.commonData.partnerInfo?.defaultCurrency || 'USD'
  }
}
