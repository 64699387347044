export function useCommonHead() {
  const { locale } = useLocale()
  const isMobile = useMobileDevice()
  const userStore = useUserStore()

  return {
    locale: locale.value,
    currency: userStore.userInfo.currency,
    channel: isMobile ? 'H5' : 'PC',
  }
}
