export function useI18n() {
  const { locale } = useLocale()
  const { localeMessages } = useNuxtApp().payload.data || {}

  function t(key: string, placeHolder?: Array<string | number | undefined> | Record<string, string | number | undefined>) {
    let translateMessage: string = localeMessages?.[locale.value]?.[key] || localeMessages?.['en-US']?.[key] || key
    if (placeHolder) {
      if (Array.isArray(placeHolder)) {
        placeHolder.forEach((value, index) => {
          translateMessage = translateMessage.replaceAll(`{${index}}`, String(value || ''))
        })
      }
      else {
        Object.keys(placeHolder).forEach((key) => {
          translateMessage = translateMessage.replaceAll(`{${key}}`, String(placeHolder[key] || ''))
        })
      }
    }
    translateMessage = _unescape(translateMessage)
    return translateMessage
  }

  return {
    t,
  }
}

function _unescape(value: string) {
  const text = String(value)
  const len = text.length
  if (!len)
    return text
  let buffer = ''
  let k = 0
  while (k < len) {
    let char = text[k]
    if (char === '\\') {
      let chars
        = k <= len - 6 && text[k + 1] === 'u'
          ? text.substring(k + 2, k + 6)
          : k <= len - 3
            ? text.substring(k + 1, k + 3)
            : ''
      if (!/^[0-9A-F]+$/i.test(chars))
        chars = ''
      if (chars.length === 4) {
        char = String.fromCharCode(Number.parseInt(chars, 16))
        k += 5
      }
      else if (chars.length === 2) {
        char = String.fromCharCode(Number.parseInt(`00${chars}`, 16))
        k += 2
      }
    }
    buffer += char
    k += 1
  }
  return buffer
}
