import { default as _91locale_93m4cGZiSFDMMeta } from "/build/pages/[locale].vue?macro=true";
import { default as _91locale_93_46htmlvdEhsWtsk8Meta } from "/build/pages/attractions/[name]/[locale].html.vue?macro=true";
import { default as _91locale_93_46htmlERR9l9oUFqMeta } from "/build/pages/attractions/booking-detail/[locale].html.vue?macro=true";
import { default as _91locale_93_46htmlo5nRRRliCoMeta } from "/build/pages/attractions/booking/[locale].html.vue?macro=true";
import { default as _91locale_93_46htmloX0EVZDdJKMeta } from "/build/pages/attractions/check-vouchers/[locale].html.vue?macro=true";
import { default as _91name_93_46_91locale_93_46htmlOluU8DAWHgMeta } from "/build/pages/attractions/detail/[countryCode]/[cityName]/[name].[locale].html.vue?macro=true";
import { default as _91locale_93_46htmlPujTPx5rBMMeta } from "/build/pages/attractions/list/[countryCode]/[cityName]/[locale].html.vue?macro=true";
import { default as _91locale_93_46htmlclz3LgR9IJMeta } from "/build/pages/bookings/[locale].html.vue?macro=true";
import { default as GroupedProducteLc96vsLBQMeta } from "/build/pages/desktop/attractions/detail/_components/GroupedProduct.vue?macro=true";
import { default as indexDyTdmsbsVvMeta } from "/build/pages/desktop/attractions/detail/index.vue?macro=true";
import { default as ListTopcLTRa2UGO1Meta } from "/build/pages/desktop/attractions/list/_components/ListTop.vue?macro=true";
import { default as SearchBarBlbCpzoxR9Meta } from "/build/pages/desktop/attractions/list/_components/SearchBar.vue?macro=true";
import { default as indexxVPbN3s2nKMeta } from "/build/pages/desktop/attractions/list/index.vue?macro=true";
import { default as indexgLWLP7OE6vMeta } from "/build/pages/desktop/coins/index.vue?macro=true";
import { default as FilterSVj4eAldYaMeta } from "/build/pages/desktop/experience/components/Filter.vue?macro=true";
import { default as SelectDateTw8apDKit8Meta } from "/build/pages/desktop/experience/components/SelectDate.vue?macro=true";
import { default as indexYLQXON7e68Meta } from "/build/pages/desktop/experience/detail/index.vue?macro=true";
import { default as indexluQGOPFg8fMeta } from "/build/pages/desktop/index.vue?macro=true";
import { default as indexE4x6E2bkFgMeta } from "/build/pages/desktop/order/create/index.vue?macro=true";
import { default as indexGwJAFTNgzDMeta } from "/build/pages/desktop/order/detail/index.vue?macro=true";
import { default as indexFvwiT6ZhUyMeta } from "/build/pages/desktop/order/list/index.vue?macro=true";
import { default as indexf2Ynn1RWjXMeta } from "/build/pages/desktop/order/search/index.vue?macro=true";
import { default as indexm0Drtc09yoMeta } from "/build/pages/desktop/order/status/index.vue?macro=true";
import { default as indexTKTPfvLbpnMeta } from "/build/pages/desktop/order/vouchers/index.vue?macro=true";
import { default as index67Q0D6EEOMMeta } from "/build/pages/desktop/payment/index.vue?macro=true";
import { default as _91locale_93_46html5MI3pHSDyqMeta } from "/build/pages/experience/detail/[countryCode]/[cityName]/[locale].html.vue?macro=true";
import { default as _91locale_93_46htmlUXHCg6FX4VMeta } from "/build/pages/experience/list/[countryCode]/[cityName]/[locale].html.vue?macro=true";
import { default as Template_2EVLjqR7SnoMeta } from "/build/pages/mk_lp/components/Template_2.vue?macro=true";
import { default as Template_3mzhL6LID6hMeta } from "/build/pages/mk_lp/components/Template_3.vue?macro=true";
import { default as TemplateAqoO8wJcC0LMeta } from "/build/pages/mk_lp/components/TemplateA.vue?macro=true";
import { default as indexvAPhgdr1c1Meta } from "/build/pages/mk_lp/index.vue?macro=true";
import { default as indexUDVl7sf3LrMeta } from "/build/pages/mk_lp/utils/index.ts?macro=true";
import { default as GroupedProductzLlYPTmT80Meta } from "/build/pages/mobile/attractions/detail/_components/GroupedProduct.vue?macro=true";
import { default as indexr829JvXTjwMeta } from "/build/pages/mobile/attractions/detail/index.vue?macro=true";
import { default as ListTopTeISvD5uGaMeta } from "/build/pages/mobile/attractions/list/_components/ListTop.vue?macro=true";
import { default as SearchBarR72XBo8PpVMeta } from "/build/pages/mobile/attractions/list/_components/SearchBar.vue?macro=true";
import { default as indexMjSFi36bccMeta } from "/build/pages/mobile/attractions/list/index.vue?macro=true";
import { default as index2t5y8vAkHyMeta } from "/build/pages/mobile/coins/index.vue?macro=true";
import { default as Filter3GAVcYF9RyMeta } from "/build/pages/mobile/experience/components/Filter.vue?macro=true";
import { default as SelectDateth0fXPu3yJMeta } from "/build/pages/mobile/experience/components/SelectDate.vue?macro=true";
import { default as indexb3VAMcIDSWMeta } from "/build/pages/mobile/experience/detail/index.vue?macro=true";
import { default as indexZ4AFTNwNnWMeta } from "/build/pages/mobile/index.vue?macro=true";
import { default as indexrMg0jjcY3uMeta } from "/build/pages/mobile/order/create/index.vue?macro=true";
import { default as index8cb5wh5BVAMeta } from "/build/pages/mobile/order/detail/index.vue?macro=true";
import { default as indexzq7rDywjBKMeta } from "/build/pages/mobile/order/list/index.vue?macro=true";
import { default as index0RWTTCGIHrMeta } from "/build/pages/mobile/order/search/index.vue?macro=true";
import { default as indextvaM1MW4HQMeta } from "/build/pages/mobile/order/status/index.vue?macro=true";
import { default as indexn2vif3l6cvMeta } from "/build/pages/mobile/order/vouchers/index.vue?macro=true";
import { default as indexBIiSmMRLSMMeta } from "/build/pages/mobile/payment/index.vue?macro=true";
import { default as _91locale_93_46htmlW1NufN0npWMeta } from "/build/pages/payment/[locale].html.vue?macro=true";
import { default as _91locale_93_46htmlAqPwRGwvaTMeta } from "/build/pages/search-bookings/[locale].html.vue?macro=true";
import { default as indexaCHdRNjJBSMeta } from "/build/pages/voluum/index.vue?macro=true";
export default [
  {
    name: "locale",
    path: "/:locale()",
    meta: _91locale_93m4cGZiSFDMMeta || {},
    component: () => import("/build/pages/[locale].vue")
  },
  {
    name: "attractions-name-locale.html",
    path: "/attractions/:name()/:locale().html",
    meta: _91locale_93_46htmlvdEhsWtsk8Meta || {},
    component: () => import("/build/pages/attractions/[name]/[locale].html.vue")
  },
  {
    name: "attractions-booking-detail-locale.html",
    path: "/attractions/booking-detail/:locale().html",
    meta: _91locale_93_46htmlERR9l9oUFqMeta || {},
    component: () => import("/build/pages/attractions/booking-detail/[locale].html.vue")
  },
  {
    name: "attractions-booking-locale.html",
    path: "/attractions/booking/:locale().html",
    meta: _91locale_93_46htmlo5nRRRliCoMeta || {},
    component: () => import("/build/pages/attractions/booking/[locale].html.vue")
  },
  {
    name: "attractions-check-vouchers-locale.html",
    path: "/attractions/check-vouchers/:locale().html",
    component: () => import("/build/pages/attractions/check-vouchers/[locale].html.vue")
  },
  {
    name: "attractions-detail-countryCode-cityName-name.locale.html",
    path: "/attractions/detail/:countryCode()/:cityName()/:name().:locale().html",
    meta: _91name_93_46_91locale_93_46htmlOluU8DAWHgMeta || {},
    component: () => import("/build/pages/attractions/detail/[countryCode]/[cityName]/[name].[locale].html.vue")
  },
  {
    name: "attractions-list-countryCode-cityName-locale.html",
    path: "/attractions/list/:countryCode()/:cityName()/:locale().html",
    meta: _91locale_93_46htmlPujTPx5rBMMeta || {},
    component: () => import("/build/pages/attractions/list/[countryCode]/[cityName]/[locale].html.vue")
  },
  {
    name: "bookings-locale.html",
    path: "/bookings/:locale().html",
    meta: _91locale_93_46htmlclz3LgR9IJMeta || {},
    component: () => import("/build/pages/bookings/[locale].html.vue")
  },
  {
    name: "desktop-attractions-detail-_components-GroupedProduct",
    path: "/desktop/attractions/detail/_components/GroupedProduct",
    component: () => import("/build/pages/desktop/attractions/detail/_components/GroupedProduct.vue")
  },
  {
    name: "desktop-attractions-detail",
    path: "/desktop/attractions/detail",
    component: () => import("/build/pages/desktop/attractions/detail/index.vue")
  },
  {
    name: "desktop-attractions-list-_components-ListTop",
    path: "/desktop/attractions/list/_components/ListTop",
    component: () => import("/build/pages/desktop/attractions/list/_components/ListTop.vue")
  },
  {
    name: "desktop-attractions-list-_components-SearchBar",
    path: "/desktop/attractions/list/_components/SearchBar",
    component: () => import("/build/pages/desktop/attractions/list/_components/SearchBar.vue")
  },
  {
    name: "desktop-attractions-list",
    path: "/desktop/attractions/list",
    component: () => import("/build/pages/desktop/attractions/list/index.vue")
  },
  {
    name: "desktop-coins",
    path: "/desktop/coins",
    component: () => import("/build/pages/desktop/coins/index.vue")
  },
  {
    name: "desktop-experience-components-Filter",
    path: "/desktop/experience/components/Filter",
    component: () => import("/build/pages/desktop/experience/components/Filter.vue")
  },
  {
    name: "desktop-experience-components-SelectDate",
    path: "/desktop/experience/components/SelectDate",
    component: () => import("/build/pages/desktop/experience/components/SelectDate.vue")
  },
  {
    name: "desktop-experience-detail",
    path: "/desktop/experience/detail",
    component: () => import("/build/pages/desktop/experience/detail/index.vue")
  },
  {
    name: "desktop",
    path: "/desktop",
    component: () => import("/build/pages/desktop/index.vue")
  },
  {
    name: "desktop-order-create",
    path: "/desktop/order/create",
    component: () => import("/build/pages/desktop/order/create/index.vue")
  },
  {
    name: "desktop-order-detail",
    path: "/desktop/order/detail",
    component: () => import("/build/pages/desktop/order/detail/index.vue")
  },
  {
    name: "desktop-order-list",
    path: "/desktop/order/list",
    component: () => import("/build/pages/desktop/order/list/index.vue")
  },
  {
    name: "desktop-order-search",
    path: "/desktop/order/search",
    component: () => import("/build/pages/desktop/order/search/index.vue")
  },
  {
    name: "desktop-order-status",
    path: "/desktop/order/status",
    component: () => import("/build/pages/desktop/order/status/index.vue")
  },
  {
    name: "desktop-order-vouchers",
    path: "/desktop/order/vouchers",
    component: () => import("/build/pages/desktop/order/vouchers/index.vue")
  },
  {
    name: "desktop-payment",
    path: "/desktop/payment",
    component: () => import("/build/pages/desktop/payment/index.vue")
  },
  {
    name: "experience-detail-countryCode-cityName-locale.html",
    path: "/experience/detail/:countryCode()/:cityName()/:locale().html",
    meta: _91locale_93_46html5MI3pHSDyqMeta || {},
    component: () => import("/build/pages/experience/detail/[countryCode]/[cityName]/[locale].html.vue")
  },
  {
    name: "experience-list-countryCode-cityName-locale.html",
    path: "/experience/list/:countryCode()/:cityName()/:locale().html",
    meta: _91locale_93_46htmlUXHCg6FX4VMeta || {},
    component: () => import("/build/pages/experience/list/[countryCode]/[cityName]/[locale].html.vue")
  },
  {
    name: "mk_lp-components-Template_2",
    path: "/mk_lp/components/Template_2",
    component: () => import("/build/pages/mk_lp/components/Template_2.vue")
  },
  {
    name: "mk_lp-components-Template_3",
    path: "/mk_lp/components/Template_3",
    component: () => import("/build/pages/mk_lp/components/Template_3.vue")
  },
  {
    name: "mk_lp-components-TemplateA",
    path: "/mk_lp/components/TemplateA",
    component: () => import("/build/pages/mk_lp/components/TemplateA.vue")
  },
  {
    name: "mk_lp",
    path: "/mk_lp",
    component: () => import("/build/pages/mk_lp/index.vue")
  },
  {
    name: "mk_lp-utils",
    path: "/mk_lp/utils",
    component: () => import("/build/pages/mk_lp/utils/index.ts")
  },
  {
    name: "mobile-attractions-detail-_components-GroupedProduct",
    path: "/mobile/attractions/detail/_components/GroupedProduct",
    component: () => import("/build/pages/mobile/attractions/detail/_components/GroupedProduct.vue")
  },
  {
    name: "mobile-attractions-detail",
    path: "/mobile/attractions/detail",
    component: () => import("/build/pages/mobile/attractions/detail/index.vue")
  },
  {
    name: "mobile-attractions-list-_components-ListTop",
    path: "/mobile/attractions/list/_components/ListTop",
    component: () => import("/build/pages/mobile/attractions/list/_components/ListTop.vue")
  },
  {
    name: "mobile-attractions-list-_components-SearchBar",
    path: "/mobile/attractions/list/_components/SearchBar",
    component: () => import("/build/pages/mobile/attractions/list/_components/SearchBar.vue")
  },
  {
    name: "mobile-attractions-list",
    path: "/mobile/attractions/list",
    component: () => import("/build/pages/mobile/attractions/list/index.vue")
  },
  {
    name: "mobile-coins",
    path: "/mobile/coins",
    component: () => import("/build/pages/mobile/coins/index.vue")
  },
  {
    name: "mobile-experience-components-Filter",
    path: "/mobile/experience/components/Filter",
    component: () => import("/build/pages/mobile/experience/components/Filter.vue")
  },
  {
    name: "mobile-experience-components-SelectDate",
    path: "/mobile/experience/components/SelectDate",
    component: () => import("/build/pages/mobile/experience/components/SelectDate.vue")
  },
  {
    name: "mobile-experience-detail",
    path: "/mobile/experience/detail",
    component: () => import("/build/pages/mobile/experience/detail/index.vue")
  },
  {
    name: "mobile",
    path: "/mobile",
    component: () => import("/build/pages/mobile/index.vue")
  },
  {
    name: "mobile-order-create",
    path: "/mobile/order/create",
    component: () => import("/build/pages/mobile/order/create/index.vue")
  },
  {
    name: "mobile-order-detail",
    path: "/mobile/order/detail",
    component: () => import("/build/pages/mobile/order/detail/index.vue")
  },
  {
    name: "mobile-order-list",
    path: "/mobile/order/list",
    component: () => import("/build/pages/mobile/order/list/index.vue")
  },
  {
    name: "mobile-order-search",
    path: "/mobile/order/search",
    component: () => import("/build/pages/mobile/order/search/index.vue")
  },
  {
    name: "mobile-order-status",
    path: "/mobile/order/status",
    component: () => import("/build/pages/mobile/order/status/index.vue")
  },
  {
    name: "mobile-order-vouchers",
    path: "/mobile/order/vouchers",
    component: () => import("/build/pages/mobile/order/vouchers/index.vue")
  },
  {
    name: "mobile-payment",
    path: "/mobile/payment",
    component: () => import("/build/pages/mobile/payment/index.vue")
  },
  {
    name: "payment-locale.html",
    path: "/payment/:locale().html",
    component: () => import("/build/pages/payment/[locale].html.vue")
  },
  {
    name: "search-bookings-locale.html",
    path: "/search-bookings/:locale().html",
    meta: _91locale_93_46htmlAqPwRGwvaTMeta || {},
    component: () => import("/build/pages/search-bookings/[locale].html.vue")
  },
  {
    name: "voluum",
    path: "/voluum",
    component: () => import("/build/pages/voluum/index.vue")
  }
]