// 自定义 fetch 插件
export default defineNuxtPlugin(() => {
  const fetch = $fetch.create({
    // 请求添加用户信息
    onRequest({ options }) {
      const originalBody = options.body || {}
      const userInfoStore = useUserStore()
      const head = useCommonHead()

      const userInfo = userInfoStore.getRowUserInfo()
      if (typeof originalBody === 'object') {
        options.body = {
          head,
          ...userInfo,
          ...originalBody,
        }
      }
    },
  })

  return {
    provide: {
      fetch,
    },
  }
})
