import { defineStore } from 'pinia'
import type { AttrDestinationDto } from '~/server/types/city.type'

const dayjs = useDayjs()
export interface FilterConditions {
  /* 时间戳 */
  date?: number
  detailDate?: number
  selectedCity?: AttrDestinationDto
  filterIdList: string[]
  defaultFilterIdList: string[]
  detailFilterIdList: string[]
  range: {
    min: number
    max: number
    currency: string
  }
}

export const useFilterConditionsStore = defineStore('filter-conditions', () => {
  const commonStore = useCommonStore()
  const useInfoStore = useUserStore()
  const filterConditions: FilterConditions = reactive({
    date: undefined, // 日期 时间戳
    detailDate: new Date().getTime(),
    selectedCity: {}, // 用户票型
    filterIdList: [], // 筛选条件
    defaultFilterIdList: [], // 默认筛选条件
    detailFilterIdList: [], // 详情页筛选条件
    range: {
      min: 0,
      max: 0,
      currency: '',
    },
  })

  const listTagList = computed(() =>
    filterConditions.filterIdList
      .filter(id => id.startsWith('1001_') || id.startsWith('1002_'))
      .map(id => id.split('_')[1])
      .filter(id => id).map(id => Number(id)),
  )

  const detailTagList = computed(() =>
    filterConditions.detailFilterIdList
      .filter(id => id.startsWith('1001_') || id.startsWith('1002_'))
      .map(id => id.split('_')[1])
      .filter(id => id).map(id => Number(id)),
  )

  const priceRange = computed(() => {
    const opiton = (commonStore.commonData.filterOpitonsInfo?.priceRanges || []).find(item => item.currency === useInfoStore.userInfo.currency)
    return opiton || {}
  })

  function getOptionsByCodes(isDetail = false) {
    const filterIdList = isDetail ? filterConditions.detailFilterIdList : filterConditions.filterIdList
    return filterIdList.flatMap((item) => {
      const firstUnderlineIndex = item.indexOf('_')
      const category = item.slice(0, firstUnderlineIndex)
      const optionCode = item.slice(firstUnderlineIndex + 1)
      const filterItem = (commonStore.commonData.filterOpitonsInfo?.filters || []).find(f => f.category === category)
      return filterItem ? (filterItem?.options || []).filter(opt => opt.optionCode === optionCode) || [] : []
    })
  }

  const handleListParams = () => {
    const params = {
      filters: getOptionsByCodes(),
      priceMin: priceRange?.value?.currency === filterConditions.range.currency ? (filterConditions.range.min || filterConditions.range.min === 0 ? filterConditions.range.min || 0 : -1) : priceRange?.value?.min,
      priceMax: priceRange?.value?.currency === filterConditions.range.currency ? (filterConditions.range.max || filterConditions.range.max === 0) ? filterConditions.range.max : -1 : priceRange?.value?.max,
    }
    return params
  }

  // 记录筛选条件
  const setRange = (range: { min: number, max: number, currency: string }) => {
    filterConditions.range = range
  }

  const getRequestFilterConditions = () => {
    return {
      destinationId: filterConditions.selectedCity?.destinationId,
      date: dayjs(filterConditions.date).format('YYYY-MM-DD'),
      detailDate: dayjs(filterConditions.detailDate).format('YYYY-MM-DD'),
    }
  }

  const filterDateRef = computed(() => ({
    get: (isDetail: boolean) => (isDetail ? filterConditions.detailDate : filterConditions.date),
    set: (isDetail: boolean, newValue: number) => {
      const newDate = new Date(newValue).getTime()
      if (isDetail) {
        filterConditions.detailDate = newDate
      }
      else {
        filterConditions.date = newDate
      }
    },
  }))

  const resetDetailFilter = () => {
    filterConditions.detailDate = filterConditions.date
    filterConditions.detailFilterIdList = filterConditions.filterIdList.filter(id => !id.startsWith('1003_'))
  }

  const resetListFilter = () => {
    filterConditions.filterIdList = filterConditions.defaultFilterIdList
    filterConditions.range = {
      min: priceRange.value.min || 0,
      max: priceRange.value.max || 0,
      currency: priceRange.value.currency || '',
    }
  }

  const resetHomeFilter = () => {
    filterConditions.date = undefined
    filterConditions.selectedCity = {}
  }

  return {
    filterConditions,
    getRequestFilterConditions,
    filterDateRef,
    listTagList,
    detailTagList,
    resetDetailFilter,
    resetListFilter,
    setRange,
    handleListParams,
    resetHomeFilter,
    getOptionsByCodes,
  }
})
