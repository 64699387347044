import routerOptions0 from "/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.4.0__ioredis@5.4._qy33elnmqgbbafdbwsgfbyjl4y/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/build/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}