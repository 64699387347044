<script setup lang="ts">
import 'vant/lib/index.css'

const { watchLocale, locale } = useLocale()

watchLocale()
await usePageConfig()
const isMobile = useMobileByScreen()
const route = useRoute()
const commonStore = useCommonStore()

const { isBurnCoinsScene } = useGlobalStore()

// 根据路由判断当前页面的布局
const layout = computed(() => {
  if (route.path.startsWith('/mk_lp')) {
    return isMobile.value ? 'mobile' : 'desktop'
  }
  return isBurnCoinsScene ? 'coins' : 'default1'
})

onMounted(() => {
  window.dispatchEvent(new Event('resize'))
})

watch(() => locale.value, () => {
  commonStore.updateInfoByLocale()
  commonStore.getCityList()
})
useGlobalCssVars()
</script>

<template>
  <!-- <ConfigProvider :use-id="useIdFunction"> -->
  <NuxtLoadingIndicator />
  <NuxtRouteAnnouncer />
  <NuxtLayout :name="layout">
    <NuxtPage />
  </NuxtLayout>
  <!-- </ConfigProvider> -->
</template>

<style>
:root:root {
  --van-popup-close-icon-color: #121212;
  --van-base-font: 'LexendDeca', sans-serif;
  --van-picker-option-padding: 0.625rem;
  --van-tag-radius: 0.25rem;
}
</style>
