<template>
  <section class="error-page">
    <div class="content">
      <h1>{{ defaultError.statusCode }}</h1>
      <p v-if="defaultError.statusCode === 404">
        {{ t('key.common.error.title.404') }}
      </p>
      <p v-else>
        {{ t('key.common.error.title.500') }}
      </p>
      <p v-if="error.message" class="error-message">
        {{ defaultError.message }}
      </p>
      <nuxt-link to="/" class="btn">
        {{ t('key.common.return.home') }}
      </nuxt-link>
    </div>
  </section>
</template>

<script setup lang="ts">
const props = defineProps<{ error: { statusCode: number, message: string } }>()
const { t } = useI18n()
const defaultError = computed(() => ({
  statusCode: props.error.statusCode ?? 500,
  message: props.error.message ?? t('key.common.error.message'),
}))
</script>

  <style scoped>
  .error-page {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #02b261, #04c481, #028751);
    color: #fff;
    text-align: center;
  }

  .content {
    max-width: 500px;
    padding: 20px;
  }

  h1 {
    font-size: 10rem;
    margin: 0;
    animation: bounce 1s infinite;
  }

  p {
    font-size: 1.5rem;
    margin: 20px 0;
  }

  .error-message {
    font-size: 1rem;
    padding: 10px;
    border-radius: 5px;
  }

  .btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #fff;
    color: #02b261;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background 0.3s;
  }

  .btn:hover {
    background-color: #02b261;
    color: #fff;
  }

  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }
  </style>
