import { ENUM_EventType, ENUM_PageName } from '~/enums'

export default defineNuxtRouteMiddleware((to) => {
  const filterConditionsStore = useFilterConditionsStore()
  const gtm = useGtm()
  const { locale } = useLocale()
  const { locales } = useNuxtApp().payload.data

  // Redirect to default locale if no locale in path
  if (to.path === '/') {
    return navigateTo(`/${locale.value}`)
  }

  // 如果 URL 中的 locale 参数不在 locales 中，则默认切换到 en-US
  if (to.params.locale && !locales.includes(to.params.locale)) {
    const newRoute = {
      ...to,
      params: {
        ...to.params,
        locale: 'en-US',
      },
    }
    return navigateTo(newRoute, { replace: true })
  }

  if (to.meta.pageCategory === ENUM_PageName.Home) {
    filterConditionsStore.resetHomeFilter()
  }

  if (to.meta.pageCategory) {
    const prd_key = to.query.tab_key as string
    const prdMap: { [key: string]: string } = {
      0: 'All',
      1: 'Attractions',
      2: 'Experiences',
    }
    const pageViewParams = {
      event: ENUM_EventType.PageView,
      city: filterConditionsStore?.filterConditions?.selectedCity?.destinationName || '',
      city_id: filterConditionsStore?.filterConditions?.selectedCity?.destinationId || '',
      campaign_id: to.query.campaign_id || '',
      campaign_name: to.query.campaign_name || '',
      locale: locale.value || '',
      country_code: filterConditionsStore?.filterConditions?.selectedCity?.countryCode || '',
      page_name: to.meta.pageCategory || '',
      prd_type: prdMap[prd_key],
    }
    gtm?.trackEvent(pageViewParams)
  }
})
